import React, { useState } from 'react';
import "./editmodel.css"
const EditModal = ({ data, onClose, onSave }) => {
  const [editedData, setEditedData] = useState({
    id: data.id,
    stock_name: data.stock_name,
    profit: data.profit,
    loss: data.loss,
    buy_quantity: data.buy_quantity,
    sell_quantity: data.sell_quantity,
    // add other fields as necessary
  });

  const handleChange = (e) => {
    setEditedData({ ...editedData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    onSave(editedData);
    onClose();
  };

  return (
    <div className="edit-modal-overlay">
      <div className="edit-modal-content">
        <h2>Edit Stock Data</h2>
        <label>Stock Name:</label>
        <input
          type="text"
          name="stock_name"
          value={editedData.stock_name}
          onChange={handleChange}
        />
        <label>Buy Price:</label>
        <input
          type="number"
          name="profit"
          value={editedData.profit}
          onChange={handleChange}
        />
        <label>Sell Price:</label>
        <input
          type="number"
          name="loss"
          value={editedData.loss}
          onChange={handleChange}
        />
        {/* Add other fields as inputs here */}
        <button onClick={handleSubmit}>Save Changes</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
};

export default EditModal;

// Add this CSS in your stylesheet or in the same file using styled-components
// or similar library

