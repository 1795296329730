export const servieUrl={

    "url":"https://brightFuture.pythonanywhere.com/",

    //  "url":"https://stockmarketing.pythonanywhere.com/",

     "otpurl":"https://brightFuture.pythonanywhere.com/"

    // "otpurl" : "https://harshitgangwar002.pythonanywhere.com/"


}