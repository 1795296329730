import React, { useState } from 'react';
import './TransactionForm.css'; // Import the CSS file for styling
import { servieUrl } from '../../env/env';

const TransactionForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        type: '',
        amount: ''
    });
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const amountstatus=()=>{
       
        var myHeaders = new Headers();
    myHeaders.append("Accept", "*/*");
    myHeaders.append("Accept-Language", "en-US,en;q=0.9");
    myHeaders.append("Connection", "keep-alive");
    myHeaders.append("DNT", "1");
    myHeaders.append("Origin", "http://localhost:3000");
    myHeaders.append("Referer", "http://localhost:3000/");
    myHeaders.append("User-Agent", "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/119.0.0.0 Safari/537.36 Avast/119.0.0.0");
    const today = new Date();
    var formdata = new FormData();
    formdata.append("date", today.toLocaleDateString('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }));
    formdata.append("price",formData.amount);
    formdata.append("loss", "0");
    formdata.append("profit", "0");
    formdata.append("user_email", formData.email);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(servieUrl.url+"growadmin/amount_account/", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        amountstatus()
        
        setShowSuccessMessage(false);

        try {
            const response = await fetch(servieUrl.url+'rolebased/transaction/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
alert("Successfully stored")
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }

            await response.json();
            setShowSuccessMessage(true);
            setFormData({ name: '', email: '', type: '', amount: '' }); // Reset form
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (
        <div className="transaction-form-container">
            <form onSubmit={handleSubmit} className="transaction-form">
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Name"
                    className="form-input"
                />
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email"
                    className="form-input"
                />
                <select name="type" value={formData.type} onChange={handleChange} className="form-select">
                    <option value="">Select Type</option>
                    <option value="Debit">Debit</option>
                    <option value="Credit">Credit</option>
                    <option value="Payout">Payout</option>
                </select>
                <input
                    type="number"
                    name="amount"
                    value={formData.amount}
                    onChange={handleChange}
                    placeholder="Amount"
                    className="form-input"
                />
                <button type="submit" className="submit-button">Submit Transaction</button>
            </form>
            {showSuccessMessage && <div className="success-message">Transaction Successful!</div>}
        </div>
    );
};

export default TransactionForm;
